import { ApiLayer } from '@/utils/api/api-layer';
import axios from '@/utils/api/client/axios';
class OrderLayer extends ApiLayer {
    createOrder(data) {
        return this.methods.post(`/orders`, data);
    }
    fetchOrderList() {
        return this.methods.get(`/orders`);
    }
    fetchOrderById(id) {
        return this.methods.get(`/orders/${id}`);
    }
    updateParticipants(id, data) {
        return this.methods.put(`/orders/${id}/participants`, data);
    }
    /**
     * Получение счета на оплату
     * @param id
     */
    fetchInvoiceById(id) {
        return this.methods.get(`/orders/${id}/invoice`, { responseType: 'blob' });
    }
}
export const OrderAPI = new OrderLayer(axios);
