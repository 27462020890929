<template>
  <page-layout>
    <page-inner :loading="init">
      <h3>{{ $trlMessage('event.management') }}</h3>
      <table :class="$style.table">
        <thead :class="$style.thead">
          <tr>
            <th v-for="(head, index) in $options.tHead" :key="index">{{ head }}</th>
          </tr>
        </thead>
        <tbody :class="$style.tbody">
          <template v-if="orderList.length">
            <tr
              v-for="order in orderList"
              :key="order.id"
              :class="order.rowClass"
              @click="handleClickTicket(order)"
            >
              <td :class="$style.orderName" v-html="order.name" />
              <td :class="$style.tariff">
                {{ order.tariff }}
              </td>
              <td :class="$style.orderCount">
                {{ order.count }}
              </td>
              <td :class="$style.orderCost" v-html="order.totalCost" />
              <td :class="$style.orderState"> <span :class="$style.point" />{{ order.state }} </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="$options.tHead.length" :class="$style.empty">
                {{ $trlMessage('event.empty') }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </page-inner>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/page-layout.vue';
import PageInner from '@/components/page/page-inner.vue';
import { OrderAPI } from '@/api/order';
import { trlMessage } from '@/services/i18n';
import { EventPaymentType } from '@/types/event';
import { localeDateShort, lang } from '@/utils/common';
import { getLocalStorageItem } from '@/utils/local-storage';

const tHead = [
  trlMessage('order.list.name'),
  trlMessage('order.list.tariff'),
  trlMessage('order.list.count'),
  trlMessage('order.list.sum'),
  trlMessage('order.list.state')
];

export default {
  name: 'OrderPage',
  components: {
    PageLayout,
    PageInner
  },
  props: {},
  tHead,
  data() {
    return {
      init: true,
      orders: []
    };
  },
  computed: {
    orderList() {
      const formatter = new Intl.NumberFormat(lang);
      const state = {
        [EventPaymentType.created]: this.$trlMessage('event.payment.created'),
        [EventPaymentType.canceled]: this.$trlMessage('event.payment.canceled'),
        [EventPaymentType.paid]: this.$trlMessage('event.payment.paid'),
        [EventPaymentType.draft]: this.$trlMessage('event.payment.draft')
      };

      const cost = (order) => {
        const postfix =
          order.state === EventPaymentType.draft
            ? `<br/><small>(${localeDateShort(order.tariff_group.date)})</small>`
            : '';

        return `${formatter.format(order.total_cost)} ₽${postfix}`;
      };

      return this.orders.map((order) => {
        const draft = order.state === EventPaymentType.draft ? order : null;
        return {
          id: order.number,
          name: order.event?.name,
          tariff: order.tariff_group.name,
          count: formatter.format(order.number_of_participants),
          totalCost: cost(order),
          state: state[order.state],
          status: order.state,
          rowClass: this.$style[order.state.toLowerCase()],
          draft
        };
      });
    }
  },
  watch: {},
  mounted() {
    const draftList = getLocalStorageItem('draft');
    for (const key in draftList) {
      this.orders.unshift(draftList[key]);
    }
    OrderAPI.fetchOrderList()
      .then((order) => {
        this.orders = [...this.orders, ...order.items];
      })
      .finally(() => {
        this.init = false;
      });
  },
  methods: {
    handleClickTicket(order) {
      if (order.status === EventPaymentType.draft) {
        this.$router.push({
          name: 'event-buy',
          params: { eventId: order.draft.event.id, typeId: order.draft.tariff_group.id },
          query: {
            type: EventPaymentType.draft,
            draftId: order.id
          }
        });
        return;
      }
      this.$router.push({ name: 'ticket', params: { ticketId: order.id } });
    }
  }
};
</script>

<style module>
.table {
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 20px 15px 20px;

    @media screen and (max-width: 600px) {
      padding: 4px 8px;
      display: flex;
      width: auto;
    }
  }
  tr {
    &:nth-child(odd) {
      background-color: #f8f8f8;
    }
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      padding-top: 8px;
      border-top: solid 1px #d8d7e0;
    }
  }
}
.thead {
  th {
    text-align: left;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  tr {
    &:last-child {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}
.tbody {
  tr {
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.orderName {
}

.tariff {
  width: 250px;
}

.orderCount {
  width: 135px;
}

.orderCost {
  width: 120px;
}

.orderState {
  width: 175px;
}

td.empty {
  text-align: center;
  padding: 30px 10px;
}

.point {
  padding-left: 15px;
  position: relative;
  &:before {
    content: '\2022';
    position: absolute;
    left: 0;
    top: -1px;
    font-size: 40px;
  }
}

.created {
  .point {
    color: #ffd95c;
  }
}
.canceled {
  color: #91909b;
  .point {
  }
}
.paid {
  .point {
    color: #219653;
  }
}

.draft {
  font-style: italic;
}
</style>
