<template>
  <button
    v-if="isLink"
    v-bind="$attrs"
    :class="$style.link"
    @click="$emit('click', $event)"
    type="button"
  >
    <slot />
  </button>
  <button
    v-else
    :class="btnClass"
    :type="type"
    :disabled="loading"
    v-bind="$attrs"
    @click="!loading ? $emit('click', $event) : null"
  >
    <svg
      v-if="loading"
      :class="$style.spinner"
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity=".5"
        d="M10 1a8.983 8.983 0 0 0-7 3.343A9 9 0 1 0 19 10c0-1.052-.18-2.062-.512-3a8.996 8.996 0 0 0-1.821-3.046"
        stroke="#9C9C9C"
        stroke-width="1.5"
      />
    </svg>
    <span :class="loading ? $style.hide : ''">
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    loading: {
      type: Boolean,
      default: false
    },
    kind: {
      type: String,
      default: ''
    }
  },
  emits: ['click'],
  computed: {
    isLink() {
      return this.type === 'link';
    },
    btnClass() {
      const isButtonStyle = this.kind !== 'link';
      return {
        [this.$style.button]: isButtonStyle,
        [this.$style.buttonPreloader]: this.loading,
        [this.$style[this.kind]]: true
      };
    }
  }
};
</script>

<style module>
.link {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  display: inline;
}

.button {
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 600;

  height: 4.8rem;
  padding: 1.6rem 1.2rem;

  font-family: Graphik, Arial, Helvetica, sans-serif;

  color: #fff;
  border: 0;
  background-color: #1d1d1f;
  border-radius: 6px;

  cursor: pointer;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px #d8d8e5;
  }

  &:active {
    box-shadow: 0 0 0 4px #d8d8e5;
  }

  &:disabled {
    background-color: #2f2f31;
    color: #9c9c9c;
    cursor: not-allowed;

    &:focus-visible,
    &:active {
      box-shadow: none;
    }
  }
}

.buttonPreloader {
  &:disabled {
    background-color: #1d1d1f;
    cursor: default;
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hide {
  opacity: 0;
}

.link {
  border: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.spinner {
  display: block;
  margin: 0 auto;
  fill: none;
  stroke-width: 1px;
  stroke-dasharray: 170px;
  animation-name: spinner-animation;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-timing-function: linear;
}

.warn {
  background: #db525e;
  border-color: #db525e;
  white-space: nowrap;

  &:hover,
  &:focus {
    border-color: rgba(0, 0, 0, 0.2);
    background-color: #db525e;
    color: #fff;
  }
  &:active {
    background-color: #c13c48;
    border-color: rgba(0, 0, 0, 0.2);
  }
}

.white {
  background: #fff;
  color: black;
  border: 1px solid #cccccc;

  &:hover {
    background: #fdfdfd;
    color: black;
    border-color: rgba(0, 0, 0, 0.2);
  }

  &:focus {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &:active {
    color: black;
    background-color: #fdfdfd;
    border-color: rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    background-color: #fff;
    color: #d5d5d5;
    cursor: not-allowed;
  }
}
</style>
